import React from "react";
import Layout from "../components/layout"
import SEO from "../components/seo"

/** @jsx jsx */
import { jsx } from "theme-ui"

export default function SobreMim() {
    const softwares = ['Visual Studio Code', 'Cmder', 'Fork'];

    return (
        <Layout>
            <SEO title="Sobre Mim" />
            <section>
                <h1>
                    Sobre Mim
                </h1>
                <p>
                    Oi. Me chamo Vinícius Luiz.
                </p>
                <p>
                    Comecei os meus estudos na programação em 2017, em 2018 conheci o mundo do front end,
                    desde lá pra cá tento me especializar nessa área, já mexi com Angular, React e Vue. 
                </p>
                <p>
                    Atualmente por causa do trabalho acabo utilizando mais o Vue, porém gosto de me aventurar no React,
                    tanto é que esse site é feito em Gatsby.
                </p>
                <p>
                    Atualmente sou um desenvolvedor de software Front-end pleno, vou tentar escrever sobre
                    Javascript, Vue e desenvolvimento Web, porém posso acabar escrever sobre outros assuntos e
                    provávelmente vou mesmo.
                </p>
                <p>
                    Para entrar em contato comigo, me mande um email: {` `}
                    <a
                        sx={{
                            variant: 'a.link'
                        }}
                        href="mailto: hi@viniciusluiz.dev"
                    >
                        hi@viniciusluiz.dev
                    </a>.
                </p>
                <section>
                    <h2 sx={{
                        variant: 'text.subtitle'
                    }}>
                        Hobbies
                    </h2>
                    <p>
                        Meu principal hobby é ouvir música, principalmente quando estou trabalhando ou fazendo
                        alguma outra coisa. Gosto muito de procurar novas músicas que eu nunca tinha ouvido antes e
                        meu gosto musical é muito aberto, também já fiz algumas músicas há um tempo, se quiser ouvir
                        clique {` `}
                        <a sx={{ variant: 'a.link' }} target="_blank" href="https://soundcloud.com/jvinsss">
                            aqui
                        </a>.
                    </p>
                </section>
                <section>
                    <h2
                        sx={{
                            variant: 'text.subtitle'
                        }}
                    >
                        Softwares que eu uso
                    </h2>
                    <ul>
                        {softwares.map((soft, index) => (
                            <li key={index}>
                                { soft }
                            </li>
                        ))}
                    </ul>
                </section>
            </section>
        </Layout>
    )
}